// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-white {
    filter: brightness(0) invert(1);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Layouts/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;EACjC","sourcesContent":[".filter-white {\n    filter: brightness(0) invert(1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

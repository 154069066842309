import {
  Alert,
  AlertColor,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DailyPayoutPropsArray,
  FleetRentalPayoutPropsArray,
} from "../../../@types/DRO/DailyPayoutResponse";
import React, { useEffect, useState } from "react";

import AppHeader from "../../Layouts/AppHeader/AppHeader";
import { MediaUploadApi } from "../../../api/MediaUploadApi";
import SideBarNavigation from "../../Layouts/Sidebar/AppSidebar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ZyppUploadApi } from "../../../api/ZyppUpload";

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};

export default function FleetRental() {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [totalCount, setTotalCount] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<"asc" | "desc" | undefined>(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("info");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (/\.(xlsx|xls)$/i.test(file.name)) {
        setSelectedFile(file);
        setSnackbarMessage("");
        setShowSnackbar(false);
      } else {
        setSnackbarMessage("Please select a .xlsx or .xls file.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
        setSelectedFile(null);
      }
    }
  };

  const handleImport = async () => {
    if (!selectedFile) {
      setSnackbarMessage("Please select a file first.");
      setSnackbarSeverity("warning");
      setShowSnackbar(true);
      return;
    }
    if (!/\.(xlsx|xls)$/i.test(selectedFile.name)) {
      setSnackbarMessage(
        "Invalid file format. Please select a .xlsx or .xls file."
      );
      setSnackbarSeverity("error");
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
    try {
      await MediaUploadApi.api.uploadFleetRentalPayout(selectedFile);
      setSnackbarMessage("File imported successfully.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      window.location.reload();
    } catch (error) {
      console.error("Error during the import process:", error);
      setSnackbarMessage("Failed to import file.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const [fleetRentalPayout, setFleetRentalPayout] = useState<
    FleetRentalPayoutPropsArray[]
  >([]);

  const [citiesList, setCitiesList] = useState<
    { cityName: string; cityCode: string }[]
  >([]);
  const [merchantsList, setMerchantsList] = useState<
    { merchantName: string; merchantId: string }[]
  >([]);
  useEffect(() => {
    setLoading(true);
    ZyppUploadApi.api
      .fleetRentalPayoutList(page, pageSize)
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data)) {
          setFleetRentalPayout(response.data);
        } else {
          console.error("Unexpected API response structure:", response);
          setFleetRentalPayout([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch daily payout list:", error);
        setLoading(false);
        setShowSnackbar(true);
      });
  }, [page, pageSize]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await ZyppUploadApi.api.getCitiesList();
        const cities = Array.isArray(response.data)
          ? response.data.map(
              (city: { cityName: string; cityCode: string }) => ({
                cityName: city.cityName,
                cityCode: city.cityCode,
              })
            )
          : [];
        setCitiesList(cities);
      } catch (error) {
        console.error("Failed to fetch cities list:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await ZyppUploadApi.api.getMerchantList();
        const merchants = Array.isArray(response.data)
          ? response.data.map(
              (merchant: { merchantName: string; merchantId: string }) => ({
                merchantName: merchant.merchantName,
                merchantId: merchant.merchantId,
              })
            )
          : [];
        setMerchantsList(merchants);
      } catch (error) {
        console.error("Failed to fetch merchants list:", error);
      }
    };

    fetchMerchants();
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const [fileUrls, setFileUrls] = useState<{ [key: number]: string }>({});
  const [errorFiles, setErrorFiles] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls: { [key: number]: string } = {};
      for (const data of fleetRentalPayout) {
        try {
          const url = await MediaUploadApi.api.getFileUrl(data.fileId);
          urls[data.fileId] = url;
        } catch (error) {
          console.error(
            "Error fetching file URL for fileId:",
            data.fileId,
            error
          );
        }
      }
      setFileUrls(urls);
    };

    if (fleetRentalPayout?.length > 0) {
      fetchFileUrls();
    }
  }, [fleetRentalPayout]);

  useEffect(() => {
    const fetchErrorFileUrls = async () => {
      const urls: { [key: string]: string } = {};
      for (const data of fleetRentalPayout) {
        if (typeof data.errorFileId !== "undefined") {
          try {
            const errorFileUrl = await MediaUploadApi.api.getFileUrl(
              data.errorFileId
            );
            urls[data.errorFileId] = errorFileUrl;
          } catch (error) {
            console.error(
              "Error fetching file URL for fileId:",
              data.errorFileId,
              error
            );
          }
        }
      }
      setErrorFiles(urls);
    };

    if (fleetRentalPayout.length > 0) {
      fetchErrorFileUrls();
    }
  }, [fleetRentalPayout]);

  useEffect(() => {
    const refreshPayoutFiles = async () => {
      try {
        await ZyppUploadApi.api.payoutFileRefresh(2);
        console.log("Payout files refreshed successfully.");
      } catch (error) {
        console.error("Failed to refresh payout files:", error);
      }
    };

    refreshPayoutFiles();
  }, []);

  const handleExport = async () => {
    if (!selectedCity || !selectedMerchant) {
      setSnackbarMessage("Please select both city and merchant.");
      setSnackbarSeverity("warning");
      setShowSnackbar(true);
      return;
    }

    setLoading(true);
    try {
      const response = await MediaUploadApi.api.getRentalFleetExport(
        selectedMerchant,
        selectedCity
      );

      const csvContent =
        "data:text/csv;charset=utf-8," +
        response.data
          .map((e) =>
            [
              e.id,
              e.cityCode,
              e.merchantId,
              e.fleetType,
              e.minRange,
              e.maxRange,
              e.slabStartAt,
              e.slabEndAt,
              e.rent,
              e.status,
              e.slabOrder,
              e.fleetAgeStart,
              e.fleetAgeEnd,
            ].join(",")
          )
          .join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "rental_fleet_export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbarMessage("Export successful.");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Failed to export data:", error);
      setSnackbarMessage("Failed to export data.");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setShowSnackbar(true);
    }
  };

  return (
    <>
      <div className="text-sm">
        <AppHeader />
      </div>
      <div className="flex">
        <SideBarNavigation />
        <div className="w-full md:w-4/5 ml-4">
          <div>
            <div>
              <p className="mr-2 mt-4 text-2xl font-semibold">
                Import Pilot Fleet Rental Sheet
              </p>
            </div>
            <div className="border-0 rounded-3xl bg-white mt-6">
              <div className="flex justify-between pl-6 pr-6">
                <div className="flex items-center">
                  <p className="mr-2 mt-2 font-semibold">Import File:</p>
                  <TextField
                    type={"file"}
                    size="small"
                    required
                    inputProps={{
                      accept: ".xlsx, .xls,",
                    }}
                    onChange={handleFileChange}
                  />
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#00CD5B",
                      px: 5,
                      ml: 2,
                      "&:hover": {
                        backgroundColor: "#63dd99",
                      },
                    }}
                    variant="contained"
                    onClick={handleImport}
                  >
                    Import
                  </Button>
                </div>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "#1976D2",
                    px: 5,
                    "&:hover": {
                      backgroundColor: "#63dd99",
                    },
                  }}
                  variant="contained"
                  onClick={async () => {
                    try {
                      const downloadLink =
                        await MediaUploadApi.api.downloadTemplate("rental");
                      const link = document.createElement("a");
                      link.href = downloadLink;
                      link.setAttribute("download", "template-multiclient.xls");
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error("Failed to download template:", error);
                      setSnackbarMessage("Failed to download template.");
                      setSnackbarSeverity("error");
                      setShowSnackbar(true);
                    }
                  }}
                >
                  Download Template
                </Button>
              </div>
              <div className="flex justify-between pl-6 pr-6 mt-4">
                <div className="flex items-center">
                  <FormControl
                    variant="outlined"
                    size="medium"
                    sx={{ minWidth: 200, mr: 2 }}
                  >
                    <InputLabel>City</InputLabel>
                    <Select
                      label="City"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                    >
                      {citiesList.map((city) => (
                        <MenuItem key={city.cityCode} value={city.cityCode}>
                          {city.cityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="medium"
                    sx={{ minWidth: 200 }}
                  >
                    <InputLabel>Merchant</InputLabel>
                    <Select
                      label="Merchant"
                      value={selectedMerchant}
                      onChange={(e) => setSelectedMerchant(e.target.value)}
                    >
                      {merchantsList.map((merchant) => (
                        <MenuItem
                          key={merchant.merchantId}
                          value={merchant.merchantId}
                        >
                          {merchant.merchantName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "#00CD5B",
                    px: 5,
                    "&:hover": {
                      backgroundColor: "#63dd99",
                    },
                  }}
                  variant="contained"
                  onClick={handleExport}
                >
                  Export
                </Button>
              </div>
              {loading ? (
                <LinearProgress
                  className="pl-6 pr-6"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : (
                <>
                  <TableContainer
                    style={{ width: "100%", overflowX: "auto" }}
                    className="pl-5 pr-5 pt-5"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={tableHeaderCellStyle}>#</TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout File
                          </TableCell>
                          {/* <TableCell style={tableHeaderCellStyle}>
                            Type
                          </TableCell> */}
                          <TableCell style={tableHeaderCellStyle}>
                            Status
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout Error File
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Last Updated Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fleetRentalPayout.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={tableBodyCellStyle}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {fileUrls[data.fileId] ? (
                                <a
                                  href={fileUrls[data.fileId]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 font-semibold"
                                >
                                  {data.fileId}
                                </a>
                              ) : (
                                data.fileId
                              )}
                            </TableCell>
                            {/* <TableCell style={tableBodyCellStyle}>
                              {data.status}
                            </TableCell> */}
                            <TableCell style={tableBodyCellStyle}>
                              {data.status}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.errorFileId &&
                              errorFiles[data.errorFileId] ? (
                                <a
                                  href={errorFiles[data.errorFileId]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-red-900 font-semibold"
                                >
                                  {data.errorFileId}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.createdTime}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={2000}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(event) => {
                      setPageSize(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                  />
                  <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      sx={{ width: "100%" }}
                      onClose={handleClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
